import React, { ChangeEvent } from 'react';
import { Panel, PanelLayout } from '../../../../components/PanelLayout';
import { FormControl, Form } from 'components/Form';
import Toggle from 'components/Toggle/Toggle';
import FormattedInputNestedLabel from 'components/FormattedInputNestedLabel/FormattedInputNestedLabel';
import styles from './ServerFlowSettingsPanel.module.scss';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import { useAppDispatch } from 'redux/store';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import { setFormIsDirty } from 'redux/actions/uiActions/formStateActions';
import { ILocationServerFlow, PosSystemType } from '@ready/dashboardv2api.contracts';

type Props = {
  control: Control<ILocationServerFlow>;
  isSaving: boolean;
  errors: FieldErrors<ILocationServerFlow>;
  defaultValues?: Partial<ILocationServerFlow>;
  posType?: PosSystemType;
};

const supportedPosTypes = [PosSystemType.ncraloha, PosSystemType.omnivore];
const isUsingSupportedPos = (posType: PosSystemType) => supportedPosTypes.includes(posType);

export const ServerFlowSettingsPanel = (props: Props) => {
  const { isSaving, defaultValues, control, errors, posType = PosSystemType.readypos } = props;
  const dispatch = useAppDispatch();

  return (
    <PanelLayout>
      <Panel title='ServerFlow' headerControls={null}>
        <Form hasGroups>
          <FormControl
            label='Status'
            labelTooltip="ServerFlow must be configured by Support. If you're interested in this functionality please reach out."
          >
            <StatusIndicator
              active={defaultValues?.serverFlowFeatureEnabled ?? false}
              activeLabel='Enabled'
              inactiveLabel='Disabled'
            />
          </FormControl>

          {isUsingSupportedPos(posType) && (
            <FormControl
              label='Lock Screen when inactive'
              additionalStyles={styles.toggleContainer}
              labelNoteComponent={
                <span className={styles.labelNote}>
                  The ServerFlow screen will lock after a selected period of inactivity. Pins used to access the app
                  come from your POS, please check with support to see if your POS supports this functionality before
                  enabling.
                </span>
              }
              disabled={isSaving}
            >
              <Controller
                name='inactivityLockEnabled'
                control={control}
                render={({ field, field: { value, onChange } }) => (
                  <Toggle
                    {...field}
                    additionalStyles={styles.toggle}
                    checked={value}
                    loading={isSaving}
                    onChange={(checked) => {
                      onChange(checked);
                      dispatch(setFormIsDirty(true));
                    }}
                  />
                )}
              />
            </FormControl>
          )}

          {isUsingSupportedPos(posType) && (
            <FormControl label='Screen Timeouts after' disabled={isSaving}>
              <div className={styles.screenTimeoutContainer}>
                <Controller
                  name='screenTimeoutDurationInMinutes'
                  control={control}
                  rules={{
                    validate: (value) => {
                      const parsedValue = parseFloat(value);
                      if (isNaN(parsedValue)) {
                        return 'Must be a valid number';
                      }
                      if (parsedValue < 0) {
                        return 'Must be a positive number';
                      }
                      return true;
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormattedInputNestedLabel
                      format='min'
                      label=''
                      value={value}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChange(e.target.value.toString());
                        dispatch(setFormIsDirty(true));
                      }}
                      withError={!!errors?.screenTimeoutDurationInMinutes?.message}
                      errorMessage={errors?.screenTimeoutDurationInMinutes?.message}
                      loading={isSaving}
                    />
                  )}
                />
              </div>
            </FormControl>
          )}

          <FormControl
            label='Use Chat Assistant'
            additionalStyles={styles.toggleContainer}
            labelNoteComponent={
              <span className={styles.labelNote}>
                This will enable chat for the guest app and allow users to request refills or ask to speak to their
                server. Notifications are displayed in the ServerFlow App.
              </span>
            }
            disabled={isSaving}
          >
            <Controller
              name='chatAssistantFeatureEnabled'
              control={control}
              render={({ field: { value, onChange } }) => (
                <Toggle
                  additionalStyles={styles.toggle}
                  checked={value}
                  onChange={(checked) => {
                    onChange(checked);
                    dispatch(setFormIsDirty(true));
                  }}
                  disabled={isSaving}
                />
              )}
            />
          </FormControl>

          <FormControl
            label='Enable Feedback'
            additionalStyles={styles.toggleContainer}
            labelNoteComponent={
              <span className={styles.labelNote}>
                This will enable the feedback prompt on the tip screen, allowing customers to share their experience in
                the ServerFlow app.
              </span>
            }
            disabled={isSaving}
          >
            <Controller
              name='feedbackEnabled'
              control={control}
              render={({ field: { value, onChange } }) => (
                <Toggle
                  additionalStyles={styles.toggle}
                  checked={value}
                  onChange={(checked) => {
                    onChange(checked);
                    dispatch(setFormIsDirty(true));
                  }}
                  disabled={isSaving}
                />
              )}
            />
          </FormControl>
        </Form>
      </Panel>
    </PanelLayout>
  );
};
